/* Мобильные устройства */
.card-container {
    width: 100%;
    padding: 10px;
}

@media (min-width: 600px) { /* Планшеты */
    .card-container {
        width: 75%;
        padding: 15px;
    }
}

@media (min-width: 900px) { /* Настольные ПК */
    .card-container {
        width: 50%;
        padding: 20px;
    }
}
