.Slider .slick-track {
    display: flex;
    align-items: center;
    margin: 0 auto; /* Выравниваем по центру, убираем любые внешние отступы */
    padding: 0; /* Убедимся, что внутренние отступы не добавляют ширину */
    box-sizing: border-box; /* Размеры должны включать padding и border */
    z-index: 0;
}

.Slider .slick-list {
    overflow: hidden; /* Это должно скрыть, но не убрать горизонтальный скролл, если он вызван .slick-track */
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

/* Проверьте все слайды, чтобы убедиться, что они вписываются в .slick-list */
.Slider .slick-slide {
    box-sizing: border-box;
    margin: 0 15px;
    /* Ваш код для вычисления ширины кажется корректным, но проверьте его на всякий случай */
}

.Paper {
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
}

.banner-img {
    width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 768px) {
    .Slider {
        padding-top: 20px; /* Добавляем верхний отступ */
        padding-bottom: 20px; /* Добавляем нижний отступ */
        margin: 0 auto;
    }

    .Slider .slick-slide {
        margin: 10px 10px; /* Оставляем текущие отступы слайда */
    }

    .Slider .slick-list {
        margin: 0 -10px; /* Это уберёт горизонтальные отступы, если они вызывают проблемы с шириной */
    }
}

.Slider .slick-center {
    transform: scale(1.08);
}


html, body {
    max-width: 100%;
}

.Slider {
    padding: 0;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .react-modal-content {
        top: 5%;
        left: 5%;
        right: 5%;
        bottom: 5%;
    }
}



