.box {
    flex-grow: 1;
    padding: 1.5rem; /* Адаптивный padding */
}

.title {
    margin-bottom: 2.5rem; /* Увеличенный адаптивный нижний отступ */
    margin-left: 0.5rem;
    font-style: italic;
    opacity: 0.8;
}

.card {
    background-color: #FEFBF6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Добавление тени */
}

.cardContent {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-style: italic;
    opacity: 0.8;
}

.bodyText {
    margin-bottom: 0.75rem;
    margin-top: 1rem;
    font-size: 1.1rem;
}


.box .MuiCard-root {
    background-color: #FEFBF6;
}

/* Медиа-запросы для адаптации под разные устройства */
@media (max-width: 600px) {
    .title {
        margin-bottom: 1.25rem; /* Меньший адаптивный отступ для мобильных устройств */
    }

    .box {
        padding: 1rem; /* Меньший адаптивный padding для мобильных устройств */
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .title {
        margin-bottom: 1.5rem; /* Отступ для планшетов */
    }

    .box {
        padding: 1.25rem; /* Padding для планшетов */
    }
}
@media (max-width: 600px) {
    .gridContainer {
        justify-content: center; /* Центрирование элементов на маленьких экранах */
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .gridContainer {
        justify-content: center; /* Центрирование элементов на средних экранах */
    }
}
