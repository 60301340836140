.back-button {
    padding: 10px 20px;  /* Увеличение отступов для большего пространства вокруг текста */
    margin-top: 20px;      /* Добавление отступа сверху и снизу для визуального разделения */
    font-size: 16px;     /* Изменение размера шрифта для лучшего соответствия дизайну */
    color: #FFFFFF;      /* Изменение цвета текста */
    background-color: #8DCBE6; /* Изменение цвета фона */
    border: none;        /* Убрать границы */
    border-radius: 4px;  /* Скругление углов */
    text-align: left;    /* Выравнивание текста по левому краю */
    display: block;      /* Блочное отображение */
    width: auto;         /* Автоматическая ширина по содержимому */
}

.back-button:hover {
    background-color: #0056b3;  /* Цвет фона при наведении */
}
