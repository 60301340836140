.contactContainer {
    background-color: #E3F6FF;
    padding: 10px 5%; /* Измененный отступ для занятия 90% страницы */
    border-radius: 5px;
    margin: 45px auto 40px;
    width: 94%; /* Установленная ширина в 90% */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}



.iconsContainer {
    margin-top: 16px; /* Добавлен отступ сверху к иконкам с текстом */
    margin-bottom: 32px; /* Увеличенный отступ снизу */
}

.numbersTitle {
    margin-bottom: 8px; /* Отступ между текстом "додаткові номери" и номерами */
    font-weight: bold; /* Стилизация текста */
    font-size: 18px; /* Размер текста */
}


.numbersContainer {
    margin-bottom: 32px; /* Отступ между блоком номеров и замечанием */
}

.contactLink {
    margin-right: 10px;
    text-decoration: none;
}

.contactImage {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 4px;

}

.additionalNumbers {
    background-color: #9DF1DF;
    padding: 5px 10px;
    border-radius: 5px;
    list-style-type: none;
    width: fit-content; /* Ширина в соответствии с содержимым */
    margin-bottom: 10px;
    font-style: italic; /* Стилизация текста */
    opacity: 0.8; /* Немного прозрачности для текста */
}

.emergencyNotice {
    background-color: #9DF1DF;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    margin-top: 32px; /* Добавленный отступ сверху */
    font-style: italic; /* Стилизация текста */
    opacity: 0.8; /* Немного прозрачности для текста */
}


.iconsWithText {
    margin-bottom: 32px; /* Увеличенный отступ снизу */
}

.additionalNumbersList {
    margin-top: 16px;
}


.additionalNumbers:not(:last-child) {
    margin-bottom: 10px; /* Увеличенный отступ между номерами */
}


ul {
    padding-left: 0;
    margin: 0;
}

.contactText {
    color: #565656; /* Замените это на желаемый цвет текста */
}

.numbersTitle {
    margin: 15px 15px 15px 0; /* Верхний, правый, нижний и левый отступы соответственно */
}

.numberWithMessengers {
    display: flex;
    align-items: center;
    margin-bottom: 15px; /* Отступ между строками с номерами */
}

.additionalNumbers {
    margin-right: 15px; /* Отступ между номером и блоком мессенджеров */
}

.messengerLinks {
    display: flex;
    align-items: center;
    gap: 10px; /* Расстояние между элементами внутри блока мессенджеров */
}

.contactLink {
    display: flex;
    align-items: center;
    /* Остальные стили для ссылок */
}

.contactImage {
    margin-left: 5px; /* Отступ слева от текста до иконки */
}

.emergencyNotice {
    margin-top: 20px; /* Отступ сверху для блока с уведомлением */
}

@media (max-width: 600px) {
    .contactContainer {
        padding: 10px 3%;
        margin: 10px auto;
        width: 90%;
    }

    .numbersTitle {
        font-size: 16px; /* Меньший размер шрифта для мобильных устройств */
    }

    .numberWithMessengers {
        display: flex;
        flex-direction: column; /* Поддерживаем выравнивание элементов по центру */
        align-items: center; /* Выравнивание по центру */
        margin-bottom: 15px; /* Отступ между записями */
    }

    .additionalNumbers {
        width: 100%; /* Номер занимает всю ширину */
        text-align: center; /* Выравниваем текст по центру */
        margin-bottom: 5px;
        margin-left: auto; /* Выравниваем по центру */
        margin-right: auto; /* Выравниваем по центру */
    }

    .messengerLinks {
        width: 100%; /* Заставляет ссылки занимать всю ширину контейнера, перенося их на новую строку */
        display: flex;
        flex-direction: column;
        align-items: center; /* Выравнивание по центру */
        gap: 10px; /* Отступ между элементами внутри блока мессенджеров */
    }

    .contactLink {
        display: flex;
        align-items: center;
        margin-top: 10px; /* Добавляет отступ сверху для ссылок, если они на новой строке */
    }

    .contactImage {
        width: 20px;
        height: 20px;
        margin-left: 5px; /* Отступ слева от текста до иконки */
    }

    .emergencyNotice {
        font-size: 14px;
    }
    .phoneNumber {
        margin-bottom: 5px; /* Отступ между номером и текстом */
    }

    .messengerText {
        margin-bottom: 5px; /* Отступ между текстом и ссылками мессенджеров */
    }
}

